import React from 'react';
import Iframe from 'react-iframe';

const CustomGraf = ({ urlId, height, width }) => (
    <div className='graf-container'>
        <Iframe
            url={"https://20.93.117.33/d-solo/ad4f6704-cf08-473c-9d20-96a2202799af/test-dashboard?orgId=1&refresh=5s&panelId=" + urlId}
            width={width}
            height={height}
            display="block"
            frameBorder={0}
        />
    </div>
);

export default CustomGraf;