import "./Dashboard.css";
import Header from "../components/common/Header";

import SmallCustomGrafWithHeading from "../components/common/SmallCustomGrafWithHeading";
import { useEffect, useState } from "react";
import SlideShowBigCustomGrafs from "../components/common/SlideShowBigCustomGrafs";

const Dashboard = ({ handleLogout }) => {

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return size;
  }

  const [windowWidth, windowHeight] = useWindowSize();

  const calculateWidth = (widthInPr) => {
    return Math.round(windowWidth * (widthInPr / 100));
  };

  const calculateHeight = (heightInPr) => {
    return Math.round(windowHeight * (heightInPr / 100));
  };
  
  return (
    <div className="bg" style={{ paddingTop: '20px', minHeight: (calculateHeight(100) - 20) + 'px' }}>
      <Header handleLogout={handleLogout} />
      <div className="row">
        <div className="main-content-container col" style={{ width: '30%' }}>
          <SmallCustomGrafWithHeading
            heading="Average X"
            urlId="7"
            heightInPx={245} />
          <SmallCustomGrafWithHeading
            heading="Average Y"
            urlId="9"
            heightInPx={245} />
          <SmallCustomGrafWithHeading
            heading="Average Z"
            urlId="8"
            heightInPx={245} />
        </div>

        <div className="secondary-content-container col">
          <SlideShowBigCustomGrafs heightInPx={880} />
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
