import "./Header.css";
import logoBCMtetc from "../assets/Short_logo.png";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

const Header = ({handleLogout}) => {
  const logout = () => {
    if (typeof handleLogout === "function") {
      handleLogout();
    } else {
      console.error("handleLogout is not a function!");
    }
  };

  return (
    <header>
      <div className="header-content">
        <div className="logo-item">
          <img className="logo" src={logoBCMtetc} alt="LOGO"></img>
          <div className="logo-text-container">
            <p className="logo-text">Dashboard</p>
          </div>
        </div>
        <div onClick={logout} className="logout">
          <LogoutRoundedIcon style={{marginRight: '10px'}} /> Logout
        </div>
      </div>
    </header>
  );
};

export default Header;