import CustomGraf from './CustomGraf';

const SmallCustomGrafWithHeading = ({ heading, urlId, heightInPx }) => {

  return (
    <div className="row">
        <div className="streched">
            <h1 className="white-heading-small">{heading}</h1>
            <CustomGraf urlId={urlId} height={heightInPx + 'px'} width="100%" />
        </div>
    </div>
  );
};

export default SmallCustomGrafWithHeading;