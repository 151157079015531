import CustomGraf from './CustomGraf';
import { IconButton } from '@mui/material';
import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material';

const BigCustomGrafWithHeading = ({ heading, urlId, heightInPx, previousOnClick, nextOnclick, changeSlide }) => {

  return (
    <div className="row">
        <div className="streched">
          <div className='justify-between'>
            <h1 className="white-heading-big">{heading}</h1>
            <div>
              <IconButton onClick={() => changeSlide('prev')}>
                <NavigateBeforeOutlined className='text-secondary' />
              </IconButton>
              <IconButton onClick={() => changeSlide('next')}>
                <NavigateNextOutlined className='text-secondary' />
              </IconButton>
            </div>
          </div>
            <div style={{ margin: '10px' }}>
              <CustomGraf urlId={urlId} height={heightInPx + 'px'} width="100%" />
            </div>
        </div>
    </div>
  );
};

export default BigCustomGrafWithHeading;