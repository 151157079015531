import React, { useState } from "react";
import "./Login.css";
import { FaUser, FaLock } from "react-icons/fa";

const Login = ({ handleLogin, loggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  if (loggedIn) {
    return null; // Wenn bereits eingeloggt, zeige nichts
  }

  return (
    <div className="container">
      <div className="login">
        <div className="header">
          <div className="text">Login</div>
          <div className="underline"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div className="input">
              <FaUser className="icon" />
              <input
                type="text"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
            </div>

            <div className="input">
              <FaLock className="icon" />
              <input
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="button-container">
            <button className="button" onClick={handleSubmit}>
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
