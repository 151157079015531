import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BigCustomGrafWithHeading from './BigCustomGrafWithHeading';

const SlideShowBigCustomGrafs = ({ heightInPx }) => {

  const slides = useMemo(() => ["x", "y", "z", "all"], []);
  const [shownSlide, setShownSlide] = useState(slides[0]);

  const changeSlide = useCallback((direction) => {
    const currentIndex = slides.indexOf(shownSlide);
    if (direction === 'next') {
      setShownSlide(slides[(currentIndex + 1) % slides.length]);
    } else if (direction === 'prev') {
      setShownSlide(slides[(currentIndex - 1 + slides.length) % slides.length]);
    }
  }, [shownSlide, slides]);

  const slideTimer = useRef(null);

  useEffect(() => {
    clearTimeout(slideTimer.current);
    slideTimer.current = setTimeout(() => {
      changeSlide('next');
    }, 10000);
  }, [shownSlide, changeSlide]);

  useEffect(() => {
    return () => {
      clearTimeout(slideTimer.current);
    };
  }, []);

  return (
    <div className="slides-container">
      <div className={shownSlide === 'x' ? 'slide fade' : 'slide d-none'}>
        <BigCustomGrafWithHeading
          changeSlide={changeSlide}
          heading={'Beschleunigungssensor x Axle'}
          urlId={2}
          heightInPx={heightInPx}
        />
      </div>
      <div className={shownSlide === 'y' ? 'slide fade' : 'slide d-none'}>
        <BigCustomGrafWithHeading
          changeSlide={changeSlide}
          heading={'Beschleunigungssensor y Axle'}
          urlId={5}
          heightInPx={heightInPx}
        />
      </div>
      <div className={shownSlide === 'z' ? 'slide fade' : 'slide d-none'}>
        <BigCustomGrafWithHeading
          changeSlide={changeSlide}
          heading={'Beschleunigungssensor z Axle'}
          urlId={3}
          heightInPx={heightInPx}
        />
      </div>
      <div className={shownSlide === 'all' ? 'slide fade' : 'slide d-none'}>
        <BigCustomGrafWithHeading
          changeSlide={changeSlide}
          heading={'Beschleunigungssensor alle Axles'}
          urlId={4}
          heightInPx={heightInPx}
        />
      </div>
    </div>
  );
};

export default SlideShowBigCustomGrafs;