import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";


const App = () => {
   const initialLoggedIn = sessionStorage.getItem("loggedIn") === "true";
   const [loggedIn, setLoggedIn] = useState(initialLoggedIn);
 
   useEffect(() => {
     sessionStorage.setItem("loggedIn", loggedIn);
   }, [loggedIn]);
 
   const handleLogin = (username, password) => {
     if (username === "admin" && password === "password") {
       setLoggedIn(true);
     } else {
       alert("Falscher Benutzername oder Passwort");
     }
   };
 
   const handleLogout = () => {
     setLoggedIn(false);
   };
 
   return (
     <div className="App">
       <div>
         <BrowserRouter>
           <Routes>
             <Route path="/" element={<Navigate to="/login" replace />} />
             <Route
               path="/login"
               element={
                 loggedIn ? (
                     <Navigate to="/dashboard" replace />
                 ) : (
                   <Login handleLogin={handleLogin} loggedIn={loggedIn} />
                 )
               }
             />
             <Route
               path="/dashboard"
               element={
                 loggedIn ? (
                   <Dashboard handleLogout={handleLogout} />
                 ) : (
                   <Navigate to="/login" replace />
                 )
               }
             />
           </Routes>
         </BrowserRouter>
       </div>
     </div>
   );
 };
 
 export default App;
